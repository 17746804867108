import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body ics-body-information flex flex-col gap-y-2" }

import {ref} from 'vue'
import {ScpInput} from '@rmh-media/scp-components'


export default /*@__PURE__*/_defineComponent({
  __name: 'Information',
  props: {
  title: {
    required: true,
    default: '',
    type: String,
  },
  description: {
    required: true,
    default: '',
    type: String,
  },
},
  emits: ['update'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const title = ref<string>(props.title)
const description = ref<string>(props.description)
const titleError = ref<string>('')

const validationTitle = (): void => {
  if (!title.value) {
    titleError.value = 'This can not be empty'
  } else {
    titleError.value = ''
  }
}

const onUpdateTitle = (val: string): void => {
  title.value = val
  validationTitle()
  onUpdate()
}

const onUpdateDescription = (val: string): void => {
  description.value = val
  onUpdate()
}

const onUpdate = (): void => {
  emit('update', {
    title: title.value,
    description: description.value,
  })
}

onUpdate()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ScpInput), {
      class: "w-full ics-body-information__title",
      label: "Title",
      placeholder: "Title",
      hint: "Mandatory",
      "has-validation": "",
      "error-message": titleError.value,
      "model-value": title.value,
      "onUpdate:modelValue": onUpdateTitle
    }, null, 8, ["error-message", "model-value"]),
    _createVNode(_unref(ScpInput), {
      class: "ics-body-information__description-textfield pt-2 rounded-lg",
      label: "Description",
      placeholder: "Description",
      "has-validation": "",
      "model-value": description.value,
      "onUpdate:modelValue": onUpdateDescription
    }, null, 8, ["model-value"])
  ]))
}
}

})