import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ics-body ics-body-location flex flex-col gap-y-2" }

import { ref } from 'vue'
  import { ScpInput } from '@rmh-media/scp-components'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'Location',
  props: {
    location: {
      required: true,
      default: '',
      type: String,
    },
    organizer: {
      required: true,
      default: '',
      type: String,
    },
  },
  emits: ['update'],
  setup(__props, { emit: __emit }) {

  const props = __props
  const emit = __emit

  const location = ref<string>(props.location)
  const locationError = ref<string>('')
  const organizer = ref<string>(props.organizer)

  const validateLocation = (): void => {
    if (!location.value) {
      locationError.value = 'This can not be empty'
    } else {
      locationError.value = ''
    }
  }

  const onUpdateLocation = (val: string): void => {
    location.value = val
    validateLocation()
    onUpdate()
  }

  const onUpdateOrganizer = (val: string): void => {
    organizer.value = val
    onUpdate()
  }

  const onUpdate = (): void => {
    emit('update', {
      location: location.value,
      organizer: organizer.value,
    })
  }

  onUpdate()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ScpInput), {
      class: "w-full ics-body-location__title",
      label: "Location",
      placeholder: "Location",
      hint: "Mandatory",
      "has-validation": "",
      "model-value": location.value,
      "error-message": locationError.value,
      "onUpdate:modelValue": onUpdateLocation
    }, null, 8, ["model-value", "error-message"]),
    _createVNode(_unref(ScpInput), {
      class: "w-full ics-body-location__organizer",
      label: "Organizer",
      placeholder: "Organizer",
      "has-validation": "",
      "model-value": organizer.value,
      "onUpdate:modelValue": onUpdateOrganizer
    }, null, 8, ["model-value"])
  ]))
}
}

})