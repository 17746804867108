<template>
  <div class="ics-body ics-body-information flex flex-col gap-y-2">
    <ScpInput
      class="w-full ics-body-information__title"
      label="Title"
      placeholder="Title"
      hint="Mandatory"
      has-validation
      :error-message="titleError"
      :model-value="title"
      @update:model-value="onUpdateTitle"
    />

    <ScpInput
      class="ics-body-information__description-textfield pt-2 rounded-lg"
      label="Description"
      placeholder="Description"
      has-validation
      :model-value="description"
      @update:model-value="onUpdateDescription"
    />
  </div>
</template>

<script lang="ts" setup>
import {ref} from 'vue'
import {ScpInput} from '@rmh-media/scp-components'

const props = defineProps({
  title: {
    required: true,
    default: '',
    type: String,
  },
  description: {
    required: true,
    default: '',
    type: String,
  },
})
const emit = defineEmits(['update'])

const title = ref<string>(props.title)
const description = ref<string>(props.description)
const titleError = ref<string>('')

const validationTitle = (): void => {
  if (!title.value) {
    titleError.value = 'This can not be empty'
  } else {
    titleError.value = ''
  }
}

const onUpdateTitle = (val: string): void => {
  title.value = val
  validationTitle()
  onUpdate()
}

const onUpdateDescription = (val: string): void => {
  description.value = val
  onUpdate()
}

const onUpdate = (): void => {
  emit('update', {
    title: title.value,
    description: description.value,
  })
}

onUpdate()
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.ics-body-information {
  .scp-input {
    @apply text-sm;

    .hint {
      @apply opacity-50;
    }

    &__label {
      @apply text-sm mb-2;
    }
  }

  > :last-child {
    border: none;
  }
}
</style>
